<template>
  <div>
    <h5 class="p-t-20 text-center bold p-b-10 auth-title">{{ $t('auth.reset password page') }}</h5>
    <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
      <form novalidate autocomplete="off" @submit.prevent="passes(resetPassword)">
        <HeaderNotice ref="notice"></HeaderNotice>
        <AppInput input-style="normal" name="new_password" type="password" :label="$t('common.new_password')"
                  rules="required|password|min:6"
                  v-model="new_password"/>
        <AppInput input-style="normal" name="new_password_confirm" type="password"
                  :label="$t('common.new_password_confirm')"
                  rules="required|confirmed:new_password" v-model="new_password_confirm"/>
        <label class="error m-t-30">※パスワードの文字数は8文字以上～20文字以下です。</label>
        <label class="error">※数字、アルファベット大文字、アルファベット小文字を最低1文字づつ含めてください。</label>
        <button class="btn btn-complete btn-auth" type="submit">{{ $t('auth.reset password') }}</button>
      </form>

    </ValidationObserver>
  </div>
</template>

<script>
import HeaderNotice from "@components/_common/HeaderNotice";

export default {
  data() {
    return {
      new_password: null,
      new_password_confirm: null,
    }
  },
  components: {HeaderNotice},
  mounted() {
    this.$request.post(this.$consts.API.AUTH.CHECK_PARAMS_RESET_PASSWORD, {
      user_id: this.$route.query.id,
      token: this.$route.query.token,
    }).then(res => {
      if (res.hasErrors()) {
        return this.$router.pushByName('link-expired');
      }
    });
  },
  methods: {
    async resetPassword() {
      let loginRoute = null;
      switch (this.$store.state.setting.authType) {
        case 'adminAuth': {
          loginRoute = this.$consts.ROUTES.ADMIN.AUTH.LOGIN
          break;
        }
        case 'csAuth': {
          loginRoute = this.$consts.ROUTES.CS.AUTH.LOGIN
          break;
        }
        case 'hotelAuth': {
          loginRoute = this.$consts.ROUTES.HOTEL.AUTH.LOGIN
          break;
        }
        case 'taxiAuth': {
          loginRoute = this.$consts.ROUTES.TAXI.AUTH.LOGIN
          break;
        }
      }
      const res = await this.$request.post(this.$consts.API.AUTH.RESET_PASSWORD, {
        id: this.$route.query.id,
        token: this.$route.query.token,
        password: this.new_password,
        password_confirmation: this.new_password_confirm,
      })

      if (!res.hasErrors()) {
        this.$router.push({name: loginRoute, query: {changePass: 'yes'}});
      } else {
        this.$refs.notice.setError(res.data.msg)
      }
    },
  }
}
</script>
